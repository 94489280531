import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { buildEndpoints } from './endpoints';

const API_URL = process.env.REACT_APP_PUBLIC_API;

const api = createApi({
  reducerPath: 'api',

  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
  }),

  endpoints: buildEndpoints,
});

export default api;

export const {
  useCreateNoteMutation,
  useGetNoteMutation,
} = api;

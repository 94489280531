import React from 'react';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import styles from './styles.module.css';

function Input({
  label,
  value,
  className,
  placeholder,
  disabled,
  fullWidth,
  onChange,
  border,
  name,
  multiline,
  withCopy,
  type,
}) {
  const cls = className
    ? [styles.input, className].join(' ')
    : styles.input;

  const copyHandler = () => navigator.clipboard.writeText(value);

  return (
    <div className={`${cls} ${fullWidth ? styles.fullWidht : ''} ${border ? styles.inputBorder : ''}`}>
      {label && (
        <p>
          {label}
        </p>
      )}

      <div className={`${styles.field} ${withCopy ? styles.fieldWithCopy : ''}`}>
        {multiline ? (
          <textarea
            value={value}
            onChange={onChange}
            disabled={disabled}
            multiple={multiline}
            type={type || 'text'}
            placeholder={placeholder}
            name={name}
          />
        ) : (
          <input
            value={value}
            onChange={onChange}
            disabled={disabled}
            multiple={multiline}
            type={type || 'text'}
            placeholder={placeholder}
            name={name}
          />
        )}

        {withCopy && (
          <button
            title="Copy"
            className={styles.copy}
            type="button"
            onClick={copyHandler}
          >
            <CopyIcon />
          </button>
        )}
      </div>
    </div>
  );
}

export default Input;

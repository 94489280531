/* eslint-disable react/button-has-type */
import React from 'react';
import Loader from 'components/Loader';
import styles from './styles.module.css';

function Button({
  children,
  onClick,
  className,
  fullWidth,
  isLoading,
  disabled,
  type,
}) {
  const cls = className
    ? [styles.button, className].join(' ')
    : styles.button;

  return (
    <button
      onClick={onClick}
      className={`${cls} ${fullWidth ? styles.fullWidth : ''}`}
      type={type || 'button'}
      disabled={disabled}
    >
      <span>
        {isLoading ? <Loader isSmall /> : children}
      </span>
    </button>
  );
}

export default Button;

import React from 'react';
import { useTranslation } from 'react-i18next';
import bg from 'assets/images/background.webp';
import logo from 'assets/images/logo.webp';
import { Helmet } from 'react-helmet-async';
import Container from './Container';
import SwitchLanguage from './SwitchLanguage';
import styles from './styles.module.css';

function Layout({ children }) {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('appTitle')}</title>
      </Helmet>

      <header className={styles.header}>
        <Container>
          <div className={styles.language}>
            <SwitchLanguage />
          </div>

          <div className={styles.head}>
            <img className={styles.logo} src={logo} alt="Umbrella" />

            <h4>
              {t('title')}
            </h4>
          </div>
        </Container>
      </header>

      <main className={styles.main}>
        <Container>
          <img className={styles.bg} src={bg} alt="bg" />

          <div className={styles.wrap}>
            {children}
          </div>
        </Container>
      </main>

      <footer className={styles.footer}>
        <Container>
          {`Umbrella Corp. ${new Date().getFullYear()}`}
        </Container>
      </footer>
    </>
  );
}

export default Layout;

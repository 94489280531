import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EnFlag } from 'assets/icons/flags/en.svg';
import { ReactComponent as RuFlag } from 'assets/icons/flags/ru.svg';
import styles from './styles.module.css';

const languages = {
  ru: <RuFlag />,
  en: <EnFlag />,
};

function SwitchLanguage() {
  const { i18n, t } = useTranslation();

  const listRef = useRef();

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!listRef.current?.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [listRef]);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setOpen(false);
  };

  const { language } = i18n;

  return (
    <div className={styles.root} ref={listRef}>
      <button
        onClick={() => setOpen((prev) => !prev)}
        className={`${styles.button} ${isOpen ? styles.buttonOpened : ''}`}
        type="button"
      >
        {languages[language]}
        {t(`language.${language}`)}
      </button>

      {isOpen && (
        <ul className={styles.list}>
          {Object.keys(languages).map((lang) => (
            <li
              key={lang}
              onClick={() => changeLanguage(lang)}
              aria-hidden="true"
            >
              {languages[lang]}
              {t(`language.${lang}`)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SwitchLanguage;

import React from 'react';
import Loader from '../Loader';
import styles from './styles.module.css';

function PageLoader({ text }) {
  return (
    <div className={styles.pageLoader}>
      <Loader text={text} />
    </div>
  );
}

export default PageLoader;

export default (builder) => ({
  getNote: builder.mutation({
    query: ({ uuid, password }) => ({
      url: `api/notes/${uuid}`,
      params: {
        password,
      },
    }),
  }),

  createNote: builder.mutation({
    query: ({ note, options, password }) => ({
      url: 'api/notes',
      method: 'post',
      body: {
        note,
        options,
        password: password || undefined,
      },
    }),
  }),
});

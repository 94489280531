import {
  BrowserRouter,
  Routes as Switch,
  Route,
} from 'react-router-dom';
import { PageLoader, Layout } from 'components';
import React, { Suspense } from 'react';

const Create = React.lazy(() => import('pages/Create'));
const Note = React.lazy(() => import('pages/Note'));

function Routes() {
  return (
    <Suspense fallback={<PageLoader />}>
      <Layout>
        <BrowserRouter>
          <Switch>
            <Route path="/" element={<Create />} />
            <Route path="/:uuid" element={<Note />} />
            <Route path="/*" element={<Create />} />
          </Switch>
        </BrowserRouter>
      </Layout>
    </Suspense>
  );
}

export default Routes;

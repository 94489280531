import React from 'react';
import styles from './styles.module.css';

function Loader({ text, isSmall }) {
  const cls = isSmall
    ? [styles.loader, styles.smallLoader].join(' ')
    : styles.loader;

  return (
    <div className={styles.loaderWrap}>
      <div className={cls}>
        <div />
        <div />
        <div />
        <div />
      </div>

      {text && (
        <div className={styles.loaderText}>
          {text}
        </div>
      )}
    </div>
  );
}

export default Loader;

import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as BackArrow } from 'assets/icons/back.svg';
import styles from './styles.module.css';

function Back({ title, href }) {
  return (
    <Link
      to={href || '/'}
      className={styles.backLink}
    >
      <BackArrow />
      {title}
    </Link>
  );
}

export default Back;
